type Position = {
    x: number;
    y: number;
};

export class LineMaker {
    private lines: SVGLineElement[];
    private svgWrappers: SVGSVGElement[];
    private readonly canvas: HTMLElement;
    private readonly svgNamespace: string = 'http://www.w3.org/2000/svg';
    private startElement: HTMLElement;
    private endElements: HTMLElement[];


    constructor(canvas: HTMLElement) {
        this.canvas = canvas;
        this.lines = [];
        this.svgWrappers = [];
    }

    private calculateRelativePosition(startPos: Position, endPos: Position): { svgLeft: number, svgTop: number, wrapperWidth: number, wrapperHeight: number } {
        const svgLeft = Math.min(startPos.x, endPos.x);
        const svgTop = Math.min(startPos.y, endPos.y);
        const wrapperWidth = Math.max(Math.abs(endPos.x - startPos.x), 4);
        const wrapperHeight = Math.max(Math.abs(endPos.y - startPos.y), 4);
        return { svgLeft, svgTop, wrapperWidth, wrapperHeight };
    }

    private createSVGWrapper(svgLeft: number, svgTop: number, wrapperWidth: number, wrapperHeight: number): SVGSVGElement {
        const svgWrapper: SVGSVGElement = document.createElementNS(this.svgNamespace, 'svg');
        svgWrapper.style.position = 'absolute';
        svgWrapper.style.left = `${svgLeft}px`;
        svgWrapper.style.top = `${svgTop}px`;
        svgWrapper.style.width = `${wrapperWidth}px`;
        svgWrapper.style.height = `${wrapperHeight}px`;
        svgWrapper.classList.add('flow-line');
        return svgWrapper;
    }

    private createRightAnglePath(startPos: Position, endPos: Position, relativeStartX: number, relativeStartY: number, relativeEndX: number, relativeEndY: number, color: string): SVGPathElement {
        const d = `M ${relativeStartX} ${relativeStartY} L ${relativeStartX} ${relativeStartY+65} L ${relativeEndX} ${relativeStartY+65} L ${relativeEndX} ${relativeEndY}`;
        const pathOffset = 5;
        const newPathD = `M ${relativeStartX + pathOffset} ${relativeStartY} L ${relativeStartX + pathOffset} ${relativeStartY + 65} L ${relativeEndX + pathOffset} ${relativeStartY + 65} L ${relativeEndX + pathOffset} ${relativeEndY}`;

        const newPath: SVGPathElement = document.createElementNS(this.svgNamespace, 'path');
        newPath.setAttribute('d', newPathD);
        newPath.setAttribute('stroke', color);
        newPath.style.strokeWidth = '4px';
        newPath.style.fill = 'none';
        return newPath;
    }

    private createLine(startElement: HTMLElement, endElement: HTMLElement, color: string = '#829AB1'): SVGPathElement {
        const startPos: Position = this.getElementPosition(startElement, 'bottom');
        const endPos: Position = this.getElementPosition(endElement, 'top');

        const { svgLeft, svgTop, wrapperWidth, wrapperHeight } = this.calculateRelativePosition(startPos, endPos);
        const relativeStartX = startPos.x - svgLeft;
        const relativeStartY = startPos.y - svgTop;
        const relativeEndX = endPos.x - svgLeft;
        const relativeEndY = endPos.y - svgTop;

        // Adjusting the wrapper height to accommodate the extra path segment
        const extraPathLength = 65; // The extra length added to the path
        const adjustedWrapperHeight = Math.max(wrapperHeight, relativeStartY + extraPathLength);
        const adjustedWrapperWidth = wrapperWidth + 10; // 5px gap on each side


        // Create the right-angle path
        const newPath = this.createRightAnglePath(startPos, endPos, relativeStartX, relativeStartY, relativeEndX, relativeEndY, color);

        // Create and append the SVG wrapper
        const svgWrapper = this.createSVGWrapper(svgLeft-5, svgTop, adjustedWrapperWidth, adjustedWrapperHeight);
        svgWrapper.appendChild(newPath);

        this.svgWrappers.push(svgWrapper);

        return newPath;
    }



    private getCanvasPosition(): Position {
        const rect: DOMRect = this.canvas.getBoundingClientRect();
        return {
            x: rect.left + window.scrollX,
            y: rect.top + window.scrollY
        };
    }

    private getElementPosition(element: HTMLElement, position: string): Position {
        const rect: DOMRect = element.getBoundingClientRect();
        const canvasPos = this.getCanvasPosition();
        if(position === 'bottom') {
            return {
                x: rect.left + window.scrollX - canvasPos.x + rect.width / 2,
                y: rect.top + window.scrollY - canvasPos.y + rect.height
            };
        } else {
            return {
                    x: rect.left + window.scrollX - canvasPos.x + rect.width / 2,
                    y: rect.top + window.scrollY - canvasPos.y
                };
        }
    }

    public initializeLines(startElement: HTMLElement, endElements: HTMLElement[]): void {
        this.destroyLines(); // Clear existing lines and wrappers
        this.startElement = startElement;
        this.endElements = endElements;

        endElements.forEach(endElem => {
            this.createLine(startElement, endElem);
        });

        this.svgWrappers.forEach(wrapper => {
            this.canvas.appendChild(wrapper);
        });
    }

    public positionLines(): void {
        this.destroyLines(); // Clear existing lines and wrappers
        this.endElements.forEach(endElem => {
            this.createLine(this.startElement, endElem);
        });

        this.svgWrappers.forEach(wrapper => {
            this.canvas.appendChild(wrapper);
        });
    }



    public destroyLines(): void {
        this.svgWrappers.forEach(wrapper => wrapper.remove());
        this.lines = [];
        this.svgWrappers = [];
    }
}
