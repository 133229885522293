document.addEventListener('alpine:init', () => {
    Alpine.data('WepSpotlightComponent', (componentId) => ({
        tokens: window.Livewire.find(componentId).$entangle('activeTokens', true),
        active: window.Livewire.find(componentId).$entangle('active', true),
        selectedGroup: 0,
        selectedItem: 0,
        query: window.Livewire.find(componentId).$entangle('query', true),
        listeners: [],
        input: {
            ['x-ref']: 'input',
            async ['@keydown.backspace']() {
                if (this.$el.value === '' && this.tokens.length > 0) {
                    await this.$wire.popScope();
                    this.selectedGroup = 0;
                    this.selectedItem = 0;

                    this.$nextTick(() => {
                        this.resetScroll();
                    });

                    this.focus();
                }
            },
            async ['@keydown.backspace.shift']() {
                this.resetScope();
            },
            ['@keydown.arrow-up'](event) {
                event.preventDefault();

                this.selectUp();
            },
            ['@keydown.arrow-down'](event) {
                event.preventDefault();

                this.selectDown();
            },
            ['@keydown.enter'](event) {
                event.preventDefault();

                this.select();
            },
            ['@keydown.tab'](event) {
                event.preventDefault();
                if (this.selectionHasTokens()) {
                    this.applyScope();
                    this.resetQuery();
                }
            },
            ['@keyup.tab'](event) {
                event.preventDefault();
            },
        },
        init() {
            this.$watch('query', () => {
                this.selectedItem = 0;
                this.selectedGroup = 0;
            });
            this.$watch('active', () => {
                if(this.active) {
                    this.focus();
                }
            });
            this.listeners.push(
                Livewire.on('spotlight.toggle', (options) => {
                this.toggle(options);
                })
            );
        },
        destroy() {
            this.listeners.forEach((listener) => {
                listener();
            });
        },
        focus() {
            setTimeout(() => {
                this.$focus.focus(this.$refs.input)
            }, 100);
        },
        close() {
            this.active = false;
        },
        toggle(options) {
            if (options?.query) {
                this.query = options.query;
            }

            this.active = !this.active;
        },
        resetQuery() {
            this.query = '';
            this.selectedItem = 0;
            this.selectedGroup = 0;

            this.focus();
        },
        resetScope() {
            this.$wire.clearScope();

            this.resetScroll();

            this.focus();
        },
        selection() {
            let groups = this.$refs.resultGroups?.children ?? [];
            let item = groups[this.selectedGroup]?.querySelectorAll('ul>li')[this.selectedItem];


            return item ? Alpine.$data(item) : null;
        },
        selectionHasTokens() {
            return this.selection()?.tokens?.length !== 0;
        },
        select() {
            if(this.selection().action) {
                this.$wire.runAction(this.selection().action);
                return;
            }

            if(this.selection().tokens) {
                this.applyScope();
            }
        },
        applyScope() {
            this.$wire.applyTokens(this.selection().tokens);

            this.$nextTick(() => {
                this.$focus.focus(this.$refs.input);
                this.resetScroll();
            });
        },
        resetScroll() {
            this.$refs.resultGroups.scrollTop = 0;
        },
        selectUp() {
            let groups = this.$refs.resultGroups.children;
            let nextItem = Math.max(0, this.selectedItem - 1);

            if (nextItem === this.selectedItem) {
                this.selectedGroup = (this.selectedGroup - 1 === -1) ? groups.length - 1 : Math.min(groups.length - 1, this.selectedGroup - 1);
                this.selectedItem = groups[this.selectedGroup].querySelectorAll('ul>li').length - 1;
            } else {
                this.selectedItem = Math.min(groups[this.selectedGroup].querySelectorAll('ul>li').length - 1, this.selectedItem - 1);
            }

            this.$nextTick(() => {
                groups[this.selectedGroup].querySelectorAll('ul>li')[this.selectedItem - 1]?.scrollIntoView({
                    block: 'nearest',
                })
            })
        },
        selectDown() {
            let groups = this.$refs.resultGroups.children;
            let nextItem = Math.min(groups[this.selectedGroup].querySelectorAll('ul>li').length - 1, this.selectedItem + 1);

            if (nextItem === this.selectedItem) {
                this.selectedGroup = (groups.length - 1 === this.selectedGroup) ? 0 : Math.min(groups.length - 1, this.selectedGroup + 1);
                this.selectedItem = 0;
            } else {
                this.selectedItem = Math.min(groups[this.selectedGroup].querySelectorAll('ul>li').length - 1, this.selectedItem + 1);
            }

            this.$nextTick(() => {
                groups[this.selectedGroup].querySelectorAll('ul>li')[this.selectedItem + 1]?.scrollIntoView({
                    block: 'nearest',
                })
            })
        },
    }));
});
