import './bootstrap';
import '../../vendor/masmerise/livewire-toaster/resources/js';

import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';

// import './libs//trix';
import ui from '@alpinejs/ui';
import intersect from '@alpinejs/intersect'
import Sortable from "sortablejs/modular/sortable.complete.esm";
import ApexCharts from 'apexcharts'
import ScrollBooster from "scrollbooster";
import { LineMaker } from './libs/line-maker';



import Clipboard from "@ryangjchandler/alpine-clipboard"
import Splide from '@splidejs/splide';

import.meta.glob([
    // '../images/**',
    '../fonts/**',
]);



import tippy from "tippy.js";
// import 'tippy.js/dist/tippy.css';


import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://f9a93f84c5e8292d0dbcf5eab0afb921@o4506230859104256.ingest.sentry.io/4506236588982272",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/approvethis\.com\/livewire/],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


Alpine.plugin(Clipboard)
Alpine.plugin(ui);
Alpine.plugin(intersect)


window.ApexCharts = ApexCharts;
window.Alpine = Alpine;
window.Splide = Splide;
window.Sortable = Sortable;
window.LineMaker = LineMaker;
window.ScrollBooster = ScrollBooster;




document.addEventListener('alpine:init', () => {
    // Magic: $tooltip
    Alpine.magic('tooltip', el => message => {
        let instance = tippy(el, { content: message, trigger: 'manual' })

        instance.show()

        setTimeout(() => {
            instance.hide()

            setTimeout(() => instance.destroy(), 150)
        }, 2000)
    })

    // Directive: x-tooltip
    Alpine.directive('tooltip', (el, { expression }) => {
        tippy(el, { content: expression })
    })
})



Livewire.start() // This will also start Alpine automatically.



